<template>
	<el-popover placement="bottom-start" width="450" trigger="hover" @show="onShow">
		<el-table :data="terms" size="mini" v-loading="loading" border max-height="300">
			<el-table-column min-width="100" prop="targetTerm" label="Target Term">
			</el-table-column>
			<el-table-column min-width="100" prop="sourceTerm" label="Source Term">
			</el-table-column>
			<el-table-column min-width="80" prop="createdAt" label="日期">
				<template slot-scope="scope">
					{{ scope.row.createdAt | formatDate}}
				</template>
			</el-table-column>
			<el-table-column min-width="60" label="操作">
				<template slot-scope="scope">
					<el-button type="text" class="text-danger" @click="onSubmit(scope.row, scope.$index)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div slot="reference">{{term}}</div>
	</el-popover>
</template>

<script>
	import {
		mapActions
	} from 'vuex'
	import Moment from 'moment'
	export default {
		props: {
			term: {
				type: String,
				default: ''
			}
		},
		inject: [
			'adminLayout'
		],
		data() {
			return {
				loading: false,
				terms: []
			}
		},
		methods: {
			...mapActions('jscx', [
				'fetchJSCXCfTermList',
				'fetchJSCXRemoveTermFromMapping'
			]),
			onShow() {
				if (this.terms.length === 0 && !this.loading) {
					this.loading = true
					const params = {
						targetTerm: this.term
					}
					this.fetchJSCXCfTermList(params)
						.then(terms => {
							this.terms = terms
						})
						.finally(() => {
							this.loading = false
						})
				}
			},
			onSubmit(term, index) {
				this.$confirm(`此操作将从关键词 ${term.targetTerm} 列表中移除 ${term.sourceTerm} , 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.adminLayout.showLoading()
					this.fetchJSCXRemoveTermFromMapping({
							params: {
								term: term.sourceTerm
							}
						})
						.then(res => {
							this.terms.splice(index, 1)
							this.$showSuccess('移除成功')
						})
						.catch(err => {
							this.$showErrMsg(err)
						})
						.finally(() => {
							this.adminLayout.hideLoading()
						})
				}).catch(() => {})
			}
		},

	}
</script>

<style>
</style>