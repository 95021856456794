<template>
	<el-popover
	  placement="bottom-start"
	  width="450"
	  trigger="hover"
	  @show="onShow">
	  <el-table :data="history" size="mini" v-loading="loading" border max-height="300">
		  <el-table-column min-width="100" prop="term" label="Term">
		  </el-table-column>
		  <el-table-column min-width="100" prop="fromStatus" label="Status">
			  <template slot-scope="scope">
				{{scope.row.fromStatus}} → {{scope.row.newStatus}}
			  </template>
		  </el-table-column>
		  <el-table-column min-width="100" label="Clicks/Epc">
			  <template slot-scope="scope">
			  	{{scope.row.clicks}} / {{scope.row.epc}}
			  </template>
		  </el-table-column>
		  <el-table-column min-width="80" prop="updatedAt" label="日期">
			  <template slot-scope="scope">
				  {{ scope.row.updatedAt | formatDate}}
			  </template>
		  </el-table-column>
	  </el-table>
	  <div slot="reference">{{status}}</div>
	</el-popover>
</template>

<script>
	import {mapActions} from 'vuex'
	import Moment from 'moment'
	export default {
		props: {
			status: {
				type: String,
				default: ''
			},
			term: {
				type: String,
				default : ''
			}
		},
		data() {
			return {
				loading: false,
				history: []
			}
		},
		methods: {
			...mapActions('jscx', [
				'fetchJSCXListTermChangeHistory'
			]),
			onShow() {
				if (this.history.length === 0 && !this.loading) {
					this.loading = true
					const params = {term:this.term}
					this.fetchJSCXListTermChangeHistory({params})
					.then(history => {
						this.history = history
					})
					.finally(() => {
						this.loading = false
					})
				}
			}
		},
		
	}
</script>

<style>
</style>