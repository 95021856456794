<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item label="Term:">
						<el-input v-model="queryForm.term" clearable size="mini"></el-input>
					</form-item>
					<form-item label="Direction:">
						<el-input v-model="queryForm.direction" size="mini" clearable></el-input>
					</form-item>
					<form-item label="Status:">
						<el-select v-model="queryForm.status" clearable size="mini">
							<el-option v-for="item in term_status_list" :label="item" :value="item"></el-option>
						</el-select>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="addOne">新 增</el-button>
					</form-item>
					<form-item>
						<upload-button action="/adms_api_v0/adscom/termDirection/import" accept=".csv" title="导入"
							tooltip="csv文件,表头为'term,direction'"></upload-button>
					</form-item>

					<form-item>
						<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="dialog.aggregatedData=true">汇总数据</el-button>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData" size="mini" @sort-change="sortChange" border>
				<el-table-column label="Term" prop="term" min-width="150">
					<template slot-scope="scope">
						<term-popover :term="scope.row.term"></term-popover>
					</template>
				</el-table-column>
				<el-table-column label="Direction" prop="direction" min-width="150">
					<template slot-scope="scope">
						<div>{{scope.row.direction || 'Others'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="Status" prop="status" min-width="100" sortable="custom">
					<template slot-scope="scope">
						<term-status-history-popover :term="scope.row.term" :status="scope.row.status"></term-status-history-popover>
					</template>
				</el-table-column>
				<el-table-column label="L7d_Conversions" prop="l3dConversion" min-width="150" sortable="custom">
					<template slot-scope="scope">
						<div>{{scope.row.l3dConversion === null ? '-' : Number(scope.row.l3dConversion).toFixed(2)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="L7d_Epc" prop="l3dEpc" min-width="150" sortable="custom">
					<template slot-scope="scope">
						<div>{{scope.row.l3dEpc === null ? '-' : Number(scope.row.l3dEpc).toFixed(2)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="L30d_Conversions" prop="l30dConversion" min-width="160" sortable="custom">
					<template slot-scope="scope">
						<div>{{scope.row.l30dConversion === null ? '-' : Number(scope.row.l30dConversion).toFixed(2)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="L30d_Epc" prop="l30dEpc" min-width="150" sortable="custom">
					<template slot-scope="scope">
						<div>{{scope.row.l30dEpc === null ? '-' : Number(scope.row.l30dEpc).toFixed(2)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="L30d_Status" prop="l30dStatus" min-width="100">
				</el-table-column>
				<el-table-column label="操作人" min-width="150">
					<template slot-scope="scope">
						<div>{{(scope.row.updatedBy || scope.row.createdBy || {}).name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="添加日期" prop="createdAt" min-width="150">
					<template slot-scope="scope">
						<div>{{(scope.row.updatedAt || scope.row.createdAt) | formatDate('YYYY-MM-DD HH:mm:ss', false)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="editRow(scope.row)" size="mini">修改</el-button>
						<el-button v-if="scope.row.status==='New'" type="text" class="text-danger"
							@click="delRow(scope.row)" size="mini">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
		<template v-if="form !== null">
			<el-dialog :title="form.id ? '修改': '新增'" :visible.sync="dialog.edit">
				<el-form ref="form" :model="form" label-width="80px" :rules="rules">
					<el-form-item label="Term" prop="term">
						<el-input v-model.trim="form.term" size="mini"
							:disabled="form.id!==undefined && form.status!=='New'"></el-input>
					</el-form-item>
					<el-form-item label="Direction" prop="direction">
						<el-select v-model="form.direction" size="mini" filterable>
							<el-option v-for="item,index in directions" :label="item" :value="item" :key="index"></el-option>
						</el-select>
						<el-button type="text" @click="loadDirections" size="mini" class="ml-3">刷新</el-button>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialog.edit = false" size="mini">取 消</el-button>
					<el-button type="primary" @click="onSubmit" size="mini">确 定</el-button>
				</div>
			</el-dialog>
		</template>
		<template v-if="form !== null">
			<el-dialog title="删除" :visible.sync="dialog.del">
				<div>您正在执行危险操作，继续请按确定</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialog.del=false" size="mini">取 消</el-button>
					<el-button type="primary" size="mini" @click="delTermDirection">确 定</el-button>
				</div>
			</el-dialog>
		</template>
		<el-dialog title="Direction 未测新词的数量" :visible.sync="dialog.aggregatedData">
			<el-table :data="aggregatedData" size="mini" border>
				<el-table-column label="Direction" prop="direction">
				</el-table-column>
				<el-table-column label="TermCount" prop="termCount" min-width="100">
				</el-table-column>
			</el-table>
		</el-dialog>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import TermPopover from '@/components/jscx/term-popover'
	import TermStatusHistoryPopover from '@/components/jscx/term-status-history-popover'
	import {
		mapActions,
		mapState
	} from 'vuex'
	export default {
		mixins: [page, common],
		inject: ['adminLayout'],
		components: {
			TermPopover,
			TermStatusHistoryPopover
		},
		data() {
			return {
				aggregatedData: [],
				term_status_list: ['New', 'Used', 'Best', 'Potential', 'Potential-Test'],
				namespace: 'jscx',
				queryForm: {
					term: '',
					status: '',
					direction: ''
				},
				q: {},
				tableData: [],
				form: null,
				dialog: {
					edit: false,
					del: false,
					aggregatedData: false
				},
				rules: {
					term: [{
						validator: (rule, value, callback) => {
							if (!value) {
								callback(new Error('Term is required'));
							} else {
								callback();
							}
						},
						message: 'Term is required',
						trigger: 'blur'
					}],
					direction: [{
						validator: (rule, value, callback) => {
							if (!value) {
								callback(new Error('Direction is required'));
							} else {
								callback();
							}
						},
						message: 'Direction is required',
						trigger: 'blur'
					}]
				},
				sort_by: {},
				directions: [],
				loadingDirections: false
			}
		},
		mounted() {
			this.loadDirections()
		},
		methods: {
			...mapActions('jscx', [
				'fetchJSCXTermDirections',
				'addJSCXTermDirection',
				'updateJSCXTermDirection',
				'delJSCXTermDirection',
				'fetchDirections'
			]),
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.fetchJSCXTermDirections({
					params: {
						...this.page,
						...this.q,
						...this.sort_by
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content.map(v => {
					return {...v, history: []}
				})
			},
			handleRawData({
				aggregatedData
			}) {
				this.aggregatedData = aggregatedData || []
			},
			buildQueryForm(queryForm) {
				let query = {};
				for (let key in queryForm) {
					if (queryForm.hasOwnProperty(key) && queryForm[key].length > 0) {
						if (key === 'date') {
							const [startDate, endDate] = queryForm[key]
							query['startDate'] = startDate
							query['endDate'] = endDate
						} else {
							query[key] = queryForm[key];
						}
					}
				}
				return query;
			},
			// Button Events
			searchEvent() {
				this.q = this.buildQueryForm(this.queryForm)
				this.page.current = 1
				this.getList()
			},
			downloadTask() {
				const q = this.buildQueryForm(this.queryForm)
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/fetchJSCXTermDirections`,
					payload: {
						...q,
						...this.sort_by
					},
					title: `JSCX-Term-Direction(${q.startDate}~${q.endDate})`,
					pagable: {
						page: 1,
						size: 50
					},
					dispatch: this.$store.dispatch,
					requestHandler() {
						const params = Array.from(arguments).reduce((lhs, rhs) => {
							return {
								...lhs,
								...rhs
							}
						}, {})
						return {
							params
						}
					}
				})
			},
			addOne() {
				this.form = {
					direction: '',
					term: ''
				}
				this.dialog.edit = true
			},
			onSubmit() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.dialog.edit = false
						this.adminLayout.showLoading()
						const payload = this.form.id ? {
							id: this.form.id,
							body: this.form
						} : this.form
						const doAction = this.form.id ? this.updateJSCXTermDirection : this.addJSCXTermDirection
						doAction(payload)
							.then(res => {
								this.adminLayout.hideLoading()
								this.$showSuccess(res)
								this.getList()
							})
							.catch(error => {
								this.adminLayout.hideLoading()
								this.$showErrMsg(error)
							})
					} else {

						return false;
					}
				});
			},
			delTermDirection() {
				this.dialog.del = false
				this.adminLayout.showLoading()
				this.delJSCXTermDirection(this.form.id)
					.then(res => {
						this.adminLayout.hideLoading()
						this.getList()
					})
					.catch(error => {
						this.adminLayout.hideLoading()
						this.$showErrMsg(error)
					})
			},
			editRow(row) {
				this.form = {
					...row
				}
				this.dialog.edit = true
			},
			delRow(row) {
				this.form = {
					...row
				}
				this.dialog.del = true
			},
			sortChange({
				column,
				prop,
				order
			}) {
				this.sort_by = order === null ? {} : {
						orderField: prop,
						orderDirection: order === 'ascending' ? 'ASC' : 'DESC'
					},
					this.searchEvent()
			},
			loadDirections() {
				if (this.loadingDirections) return
				this.loadingDirections = true
				this.fetchDirections()
				.then(content => {
					this.directions = content
				})
				.finally(() => {
					this.loadingDirections = false
				})
			}
		},
		watch: {
			'dialog.edit'(newVal, oldVal) {
				if (!newVal) {
					this.$refs.form.clearValidate()
				}
			}
		}
	}
</script>

<style lang="scss">
</style>